import { useState } from 'react'
import { Modal } from '@mui/material'
import KenomiABI from "../../Contract/Kenomi.json";
import TextField from '@mui/material/TextField';
import { BACKEND_URL } from '../../hooks/WalletLogin';
import axios from 'axios';
const Web3 = require('web3');
const web3 = new Web3('https://mainnet.infura.io/v3/57e8f9860a3046eea1b604439012bbd3');

const BidModal = ({ openModal, setopenModal, nft, setupdateItems }) => {
    const [Bid, setBid] = useState(0);
    const [Error, setError] = useState('');

    const style = {
        background: 'rgba(0,0,0,0.6)',
        width: '100vw',
        height: '100vh',
        backdropFilter: 'blur(10px)',
    }
    
  const placeBid = async () => {
    setError('')
    console.log(Bid)
    const web3 = new Web3(Web3.givenProvider);
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0x1' }],
      })
    } catch (error) {
      console.log(error)
    }

    const accounts = await web3.eth.getAccounts();

     var kenomiInstance = new web3.eth.Contract(
      KenomiABI.abi,
      "0xb82845aeD5131213DEa0a45fAd2ccE920EC332f3"
    );

    const nonce = await kenomiInstance.methods.nonce().call();
    console.log("Nonce value is: ", nonce);

    console.log("msg.sender: ", accounts[0])

    const sigParameter = web3.utils.encodePacked(
      { value: accounts[0], type: "address" },
      { value: nonce, type: "uint256" }
    );

    console.log(sigParameter)

    const hashParameter = web3.utils.soliditySha3({
      type: "bytes",
      value: sigParameter,
    });

    console.log(hashParameter)

    const signature = await web3.eth.accounts.sign(
      hashParameter,
      "57f70516dac3f311474824603ae28da6d866168b8d9264d05bcd620ca68348df"
    ); 

    console.log(signature)
    
    const value = nft.highestBid;
    console.log(value, Bid*10**18)

    if(Bid*10**18 > (value || 0)) {
      await axios.post(`${BACKEND_URL}/auth/participate`, { index: nft.index, walletAddress: localStorage.getItem('WalletAddress'), title: nft.title, discordUsername: `${localStorage.getItem('Discord')}#${localStorage.getItem('DiscordDiscriminator')}`, pricePerItem: nft.pricePerItem, imageUrl: nft.imageUrl })
      const call = await kenomiInstance.methods.placeBid(signature.signature, nft.index).send({ from: accounts[0], value: Bid*10**18 });
      console.log(call)
      setupdateItems((prev) => !prev)
    } else {
      setError("Bid value should be greater that Highest Bid Value")
    }

  };

    return (
        <Modal
            open={openModal}
            onClose={() => setopenModal(false)}
            BackdropProps={{ style: style }}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
            <div className='bidModal'>
            <div style={{
                background: 'linear-gradient(rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.8) 100%)',
                width: '100%',
                height: '100%',
                backdropFilter: 'blur(50px)',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}>
        <h3 style={{
            fontFamily: "Ambit600",
            marginTop: '25px',
            fontSize: '22px',
            lineHeight: '32px',
            color: 'black',
        }}>Enter Bid Amount</h3>
        <TextField
            style={{ width: '336px' }}
          id="standard-search"
          label="Amount (Eth)"
          type="Number"
          variant="standard"
          onChange={(e) => setBid(e.target.value)}
        />
        </div>
        <div style={{ background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.8) 100%)', height: '120px',    display: 'flex',
    flexDirection: 'column',
    gap: '10px', }} className="popup-text-secondary">
          <div
            className="popup-text-secondary-button"
            onClick={() => placeBid()}
          >
            Place Bid
          </div>
          <p style={{ color: '#e00000' }}>{Error}</p>
        </div>
      </div>
        </Modal>
    )
}

export default BidModal
