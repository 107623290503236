import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import { WalletConnectProviderHook } from "./hooks/WalletLogin";
const container = document.getElementById("root");
const root = ReactDOM.createRoot(container);
root.render(
  <WalletConnectProviderHook>
      <App />
  </WalletConnectProviderHook>
);
