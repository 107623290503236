import React from "react";
import "./LogoutModal.css";
const LogoutModal = ({ SetLM, lg }) => {
  return (
    <div className="lg-modal">
      <div className="lg-modal-box">
        <img src={'https://res.cloudinary.com/foxledgerstudio/image/upload/c_fill,g_xy_center,q_auto:best,w_500/v1668385953/KenomiLogOut.webp'} alt="Kenomi" className="loli" />
        <div className="lg-modal-box-txt">
          Are you sure you want to log out ?
        </div>
        <div className="lg-modalbtn-box">
          <div className="lg-modal-btn-secondary" onClick={() => SetLM(false)}>
            Go Back
          </div>
          <div className="lg-modal-btn-primary" onClick={() => lg()}>
            Log Out
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogoutModal;
