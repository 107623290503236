import React, { useState, useContext } from "react";
import Navbar from "../../Components/Navbar/Navbar";
import "./community.css";
import { FaDiscord } from "react-icons/fa";
import { BsTwitter } from "react-icons/bs";
import { BsMedium } from "react-icons/bs";
import arr from "../../Assets/Images/arr.png";
import Graph from "../../Components/Graph/Graph";
import blarr from "../../Assets/Images/blarr.png";
import { WalletConnectContext } from "../../hooks/WalletLogin";
import Marquee from "react-fast-marquee";
const Community = (props) => {
  const { isWalletLogin, connectWalletThroughMetamask } =
    useContext(WalletConnectContext);
  const [top, setTop] = useState(1);
  return (
    <div className="page">
      <div className="imported-nav">
        <Navbar marketData={props.marketData} title="Community" />
      </div>
      <div className="community-hero">
        <h1 className="community-hero-title">Kenomi community</h1>
        <div className="community-hero-slider">
          <Marquee
            speed={60}
            gradientWidth={0}
          >
            <img src={'https://res.cloudinary.com/foxledgerstudio/image/upload/c_fill,g_xy_center,q_auto:best,w_250/v1667924564/Fdbr1DxaMAAFR6S_dgtzuf.webp'} alt="nft" className="hero-slider-img" />
            <img src={'https://res.cloudinary.com/foxledgerstudio/image/upload/c_fill,g_xy_center,q_auto:best,w_250/v1667924564/FeJcCe4aUAEaOOM_jefzbk.webp'} alt="nft" className="hero-slider-img" />
            <img src={'https://res.cloudinary.com/foxledgerstudio/image/upload/c_fill,g_xy_center,q_auto:best,w_250/v1670182314/Fida76rVUAA2w8Q_utcgir.webp'} alt="nft" className="hero-slider-img" />
            <img src={'https://res.cloudinary.com/foxledgerstudio/image/upload/c_fill,g_xy_center,q_auto:best,w_250/v1667924564/Ffmg3cPaYAEinDv_h9k9ld.webp'} alt="nft" className="hero-slider-img" />
            <img src={'https://res.cloudinary.com/foxledgerstudio/image/upload/c_fill,g_xy_center,q_auto:best,w_250/v1667924564/Fds33n9acAEXYDQ_dt7sex.webp'} alt="nft" className="hero-slider-img" />
            <img src={'https://res.cloudinary.com/foxledgerstudio/image/upload/c_fill,g_xy_center,q_auto:best,w_250/v1670331337/6o_ziqbbi.webp'} alt="nft" className="hero-slider-img" />
            <img src={'https://res.cloudinary.com/foxledgerstudio/image/upload/c_fill,g_xy_center,w_250/v1670331337/o7b_tcozlz.webp'} alt="nft" className="hero-slider-img" />
            <img src={'https://res.cloudinary.com/foxledgerstudio/image/upload/c_fill,g_xy_center,q_auto:best,w_250/v1670333596/o1b_r1sv0l.webp'} alt="nft" className="hero-slider-img" />
            <img src={'https://res.cloudinary.com/foxledgerstudio/image/upload/c_fill,g_xy_center,q_auto:best,w_250/v1667924564/Fdbr1DxaMAAFR6S_dgtzuf.webp'} alt="nft" className="hero-slider-img" />
            <img src={'https://res.cloudinary.com/foxledgerstudio/image/upload/c_fill,g_xy_center,q_auto:best,w_250/v1667924564/FeJcCe4aUAEaOOM_jefzbk.webp'} alt="nft" className="hero-slider-img" />
            <img src={'https://res.cloudinary.com/foxledgerstudio/image/upload/c_fill,g_xy_center,q_auto:best,w_250/v1670182314/Fida76rVUAA2w8Q_utcgir.webp'} alt="nft" className="hero-slider-img" />
            <img src={'https://res.cloudinary.com/foxledgerstudio/image/upload/c_fill,g_xy_center,q_auto:best,w_250/v1667924564/Ffmg3cPaYAEinDv_h9k9ld.webp'} alt="nft" className="hero-slider-img" />
            <img src={'https://res.cloudinary.com/foxledgerstudio/image/upload/c_fill,g_xy_center,q_auto:best,w_250/v1667924564/Fds33n9acAEXYDQ_dt7sex.webp'} alt="nft" className="hero-slider-img" />
            <img src={'https://res.cloudinary.com/foxledgerstudio/image/upload/c_fill,g_xy_center,q_auto:best,w_250/v1670331337/6o_ziqbbi.webp'} alt="nft" className="hero-slider-img" />
            <img src={'https://res.cloudinary.com/foxledgerstudio/image/upload/c_fill,g_xy_center,w_250/v1670331337/o7b_tcozlz.webp'} alt="nft" className="hero-slider-img" />
            <img src={'https://res.cloudinary.com/foxledgerstudio/image/upload/c_fill,g_xy_center,q_auto:best,w_250/v1670333596/o1b_r1sv0l.webp'} alt="nft" className="hero-slider-img" />
          </Marquee>
        </div>
        <div className="mob-com-slider">
          <img src={'https://res.cloudinary.com/foxledgerstudio/image/upload/c_fill,g_xy_center,w_500/v1670342358/Group_34565_xf1ew4.webp'} className="mob-com-slider-img" />
        </div>
        <p style={{ marginBottom: '40px' }} className="community-hero-para">
        Kenomi is a project that believes in NFTs will be the new way of fundraising. Kenomi is aiming to create a public marketplace that integrates charities. The marketplace allows members to purchase whitelist spots, online gift cards, and physical items. After buyers make the purchase, the funds generated would be donated to various charities. By creating Kenomi, our team hopes to send out a message telling people that NFTs are not useless and are not also cash grabs. NFTs can also be utilized in many different ways. Our team hopes people will use their money for good instead of gambling for profit.
        </p>
      </div>
      {/* <div className="community-activity">
        <h1 className="com-activity-title">Activity</h1>
        <Graph />
      </div> */}
      <div className="com-links">
        <div className="com-link">
          <a style={{ marginTop: '5px' }} target="_blank" href="https://discord.com/invite/wNndP3Fm4v"><FaDiscord className="com-logo" /></a>
        </div>
        <div className="com-link">
          <a style={{ marginTop: '5px' }} target="_blank" href="https://twitter.com/KenomiNFT"><BsTwitter className="com-logo" /></a>
        </div>
        <div className="com-link">
        <a style={{ marginTop: '5px' }} target="_blank" href="https://medium.com/@kenominft"><BsMedium className="com-logo" /></a>
        </div>
      </div>
      {/* <div className="community-listings-div">
        <div className="mob-black-wash" />
        <div className="selection-div">
          <div
            className={top ? "selection-div-active" : "selection-div-passive"}
            onClick={() => setTop(!top)}
          >
            TOP LISTINGS
          </div>
          <div
            className={!top ? "selection-div-active" : "selection-div-passive"}
            onClick={() => setTop(!top)}
          >
            SALES
          </div>
        </div>
        <div className="community-listings-flex">
          <h1 className="community-listings-header">top listings</h1>
          <div className="com-lists">
            <div className="com-lists-item">
              <img src={'https://res.cloudinary.com/foxledgerstudio/image/upload/c_fill,g_xy_center,q_auto:best,w_250/v1667924564/Fdbr1DxaMAAFR6S_dgtzuf.webp'} className="com-lists-item-img" />
              <div className="com-lists-item-text">
                <div className="com-lists-item-text-titles">
                  <span className="com-lists-item-text-title">
                    Kenomi #3456
                  </span>
                  <span className="com-lists-item-text-subtitle">
                    21/10/2022 at 10:30 PM
                  </span>
                </div>
                <div className="com-lists-buy">0.5 ETH</div>
                <div className="com-lists-buy-box">
                  <span className="com-lists-buy-box-span">BUY</span>
                  <img src={arr} alt="BUY" className="arr-icon" />
                </div>
              </div>
            </div>
            <div className="com-lists-item">
              <img src={'https://res.cloudinary.com/foxledgerstudio/image/upload/c_fill,g_xy_center,q_auto:best,w_250/v1667924564/Fdbr1DxaMAAFR6S_dgtzuf.webp'} className="com-lists-item-img" />
              <div className="com-lists-item-text">
                <div className="com-lists-item-text-titles">
                  <span className="com-lists-item-text-title">
                    Kenomi #3456
                  </span>
                  <span className="com-lists-item-text-subtitle">
                    21/10/2022 at 10:30 PM
                  </span>
                </div>
                <div className="com-lists-buy">0.5 ETH</div>
                <div className="com-lists-buy-box">
                  <span className="com-lists-buy-box-span">BUY</span>
                  <img src={arr} alt="BUY" className="arr-icon" />
                </div>
              </div>
            </div>
            <div className="com-lists-item">
              <img src={'https://res.cloudinary.com/foxledgerstudio/image/upload/c_fill,g_xy_center,q_auto:best,w_250/v1667924564/Fdbr1DxaMAAFR6S_dgtzuf.webp'} className="com-lists-item-img" />
              <div className="com-lists-item-text">
                <div className="com-lists-item-text-titles">
                  <span className="com-lists-item-text-title">
                    Kenomi #3456
                  </span>
                  <span className="com-lists-item-text-subtitle">
                    21/10/2022 at 10:30 PM
                  </span>
                </div>
                <div className="com-lists-buy">0.5 ETH</div>
                <div className="com-lists-buy-box">
                  <span className="com-lists-buy-box-span">BUY</span>
                  <img src={arr} alt="BUY" className="arr-icon" />
                </div>
              </div>
            </div>
            <div className="com-lists-item">
              <img src={'https://res.cloudinary.com/foxledgerstudio/image/upload/c_fill,g_xy_center,q_auto:best,w_250/v1667924564/Fdbr1DxaMAAFR6S_dgtzuf.webp'} className="com-lists-item-img" />
              <div className="com-lists-item-text">
                <div className="com-lists-item-text-titles">
                  <span className="com-lists-item-text-title">
                    Kenomi #3456
                  </span>
                  <span className="com-lists-item-text-subtitle">
                    21/10/2022 at 10:30 PM
                  </span>
                </div>
                <div className="com-lists-buy">0.5 ETH</div>
                <div className="com-lists-buy-box">
                  <span className="com-lists-buy-box-span">BUY</span>
                  <img src={arr} alt="BUY" className="arr-icon" />
                </div>
              </div>
            </div>
            <div className="com-lists-item">
              <img src={'https://res.cloudinary.com/foxledgerstudio/image/upload/c_fill,g_xy_center,q_auto:best,w_250/v1667924564/Fdbr1DxaMAAFR6S_dgtzuf.webp'} className="com-lists-item-img" />
              <div className="com-lists-item-text">
                <div className="com-lists-item-text-titles">
                  <span className="com-lists-item-text-title">
                    Kenomi #3456
                  </span>
                  <span className="com-lists-item-text-subtitle">
                    21/10/2022 at 10:30 PM
                  </span>
                </div>
                <div className="com-lists-buy">0.5 ETH</div>
                <div className="com-lists-buy-box">
                  <span className="com-lists-buy-box-span">BUY</span>
                  <img src={arr} alt="BUY" className="arr-icon" />
                </div>
              </div>
            </div>
            <div className="com-lists-item">
              <img src={'https://res.cloudinary.com/foxledgerstudio/image/upload/c_fill,g_xy_center,q_auto:best,w_250/v1667924564/Fdbr1DxaMAAFR6S_dgtzuf.webp'} className="com-lists-item-img" />
              <div className="com-lists-item-text">
                <div className="com-lists-item-text-titles">
                  <span className="com-lists-item-text-title">
                    Kenomi #3456
                  </span>
                  <span className="com-lists-item-text-subtitle">
                    21/10/2022 at 10:30 PM
                  </span>
                </div>
                <div className="com-lists-buy">0.5 ETH</div>
                <div className="com-lists-buy-box">
                  <span className="com-lists-buy-box-span">BUY</span>
                  <img src={arr} alt="BUY" className="arr-icon" />
                </div>
              </div>
            </div>
            <div className="com-lists-item">
              <img src={'https://res.cloudinary.com/foxledgerstudio/image/upload/c_fill,g_xy_center,q_auto:best,w_250/v1667924564/Fdbr1DxaMAAFR6S_dgtzuf.webp'} className="com-lists-item-img" />
              <div className="com-lists-item-text">
                <div className="com-lists-item-text-titles">
                  <span className="com-lists-item-text-title">
                    Kenomi #3456
                  </span>
                  <span className="com-lists-item-text-subtitle">
                    21/10/2022 at 10:30 PM
                  </span>
                </div>
                <div className="com-lists-buy">0.5 ETH</div>
                <div className="com-lists-buy-box">
                  <span className="com-lists-buy-box-span">BUY</span>
                  <img src={arr} alt="BUY" className="arr-icon" />
                </div>
              </div>
            </div>
            <div className="com-lists-item">
              <img src={'https://res.cloudinary.com/foxledgerstudio/image/upload/c_fill,g_xy_center,q_auto:best,w_250/v1667924564/Fdbr1DxaMAAFR6S_dgtzuf.webp'} className="com-lists-item-img" />
              <div className="com-lists-item-text">
                <div className="com-lists-item-text-titles">
                  <span className="com-lists-item-text-title">
                    Kenomi #3456
                  </span>
                  <span className="com-lists-item-text-subtitle">
                    21/10/2022 at 10:30 PM
                  </span>
                </div>
                <div className="com-lists-buy">0.5 ETH</div>
                <div className="com-lists-buy-box">
                  <span className="com-lists-buy-box-span">BUY</span>
                  <img src={arr} alt="BUY" className="arr-icon" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="community-listings-flex">
          <h1 className="community-listings-header">Sales</h1>
          <div className="com-lists">
            <div className="com-lists-item">
              <img src={'https://res.cloudinary.com/foxledgerstudio/image/upload/c_fill,g_xy_center,q_auto:best,w_250/v1667924564/Fdbr1DxaMAAFR6S_dgtzuf.webp'} className="com-lists-item-img" />
              <div className="com-lists-item-text">
                <div className="com-lists-item-text-titles">
                  <span className="com-lists-item-text-title">
                    Kenomi #3456
                  </span>
                  <span className="com-lists-item-text-subtitle">
                    21/10/2022 at 10:30 PM
                  </span>
                </div>
                <div className="com-list-buy-info">
                  <span className="com-list-buy-info-span-1">0.5 ETH</span>
                  <span className="com-list-buy-info-span-2">+ 12% floor</span>
                </div>
              </div>
            </div>
            <div className="com-lists-item">
              <img src={'https://res.cloudinary.com/foxledgerstudio/image/upload/c_fill,g_xy_center,q_auto:best,w_250/v1667924564/Fdbr1DxaMAAFR6S_dgtzuf.webp'} className="com-lists-item-img" />
              <div className="com-lists-item-text">
                <div className="com-lists-item-text-titles">
                  <span className="com-lists-item-text-title">
                    Kenomi #3456
                  </span>
                  <span className="com-lists-item-text-subtitle">
                    21/10/2022 at 10:30 PM
                  </span>
                </div>
                <div className="com-list-buy-info">
                  <span className="com-list-buy-info-span-1">0.5 ETH</span>
                  <span className="com-list-buy-info-span-2">+ 12% floor</span>
                </div>
              </div>
            </div>
            <div className="com-lists-item">
              <img src={'https://res.cloudinary.com/foxledgerstudio/image/upload/c_fill,g_xy_center,q_auto:best,w_250/v1667924564/Fdbr1DxaMAAFR6S_dgtzuf.webp'} className="com-lists-item-img" />
              <div className="com-lists-item-text">
                <div className="com-lists-item-text-titles">
                  <span className="com-lists-item-text-title">
                    Kenomi #3456
                  </span>
                  <span className="com-lists-item-text-subtitle">
                    21/10/2022 at 10:30 PM
                  </span>
                </div>
                <div className="com-list-buy-info">
                  <span className="com-list-buy-info-span-1">0.5 ETH</span>
                  <span className="com-list-buy-info-span-2">+ 12% floor</span>
                </div>
              </div>
            </div>
            <div className="com-lists-item">
              <img src={'https://res.cloudinary.com/foxledgerstudio/image/upload/c_fill,g_xy_center,q_auto:best,w_250/v1667924564/Fdbr1DxaMAAFR6S_dgtzuf.webp'} className="com-lists-item-img" />
              <div className="com-lists-item-text">
                <div className="com-lists-item-text-titles">
                  <span className="com-lists-item-text-title">
                    Kenomi #3456
                  </span>
                  <span className="com-lists-item-text-subtitle">
                    21/10/2022 at 10:30 PM
                  </span>
                </div>
                <div className="com-list-buy-info">
                  <span className="com-list-buy-info-span-1">0.5 ETH</span>
                  <span className="com-list-buy-info-span-2">+ 12% floor</span>
                </div>
              </div>
            </div>
            <div className="com-lists-item">
              <img src={'https://res.cloudinary.com/foxledgerstudio/image/upload/c_fill,g_xy_center,q_auto:best,w_250/v1667924564/Fdbr1DxaMAAFR6S_dgtzuf.webp'} className="com-lists-item-img" />
              <div className="com-lists-item-text">
                <div className="com-lists-item-text-titles">
                  <span className="com-lists-item-text-title">
                    Kenomi #3456
                  </span>
                  <span className="com-lists-item-text-subtitle">
                    21/10/2022 at 10:30 PM
                  </span>
                </div>
                <div className="com-list-buy-info">
                  <span className="com-list-buy-info-span-1">0.5 ETH</span>
                  <span className="com-list-buy-info-span-2">+ 12% floor</span>
                </div>
              </div>
            </div>
            <div className="com-lists-item">
              <img src={'https://res.cloudinary.com/foxledgerstudio/image/upload/c_fill,g_xy_center,q_auto:best,w_250/v1667924564/Fdbr1DxaMAAFR6S_dgtzuf.webp'} className="com-lists-item-img" />
              <div className="com-lists-item-text">
                <div className="com-lists-item-text-titles">
                  <span className="com-lists-item-text-title">
                    Kenomi #3456
                  </span>
                  <span className="com-lists-item-text-subtitle">
                    21/10/2022 at 10:30 PM
                  </span>
                </div>
                <div className="com-list-buy-info">
                  <span className="com-list-buy-info-span-1">0.5 ETH</span>
                  <span className="com-list-buy-info-span-2">+ 12% floor</span>
                </div>
              </div>
            </div>
            <div className="com-lists-item">
              <img src={'https://res.cloudinary.com/foxledgerstudio/image/upload/c_fill,g_xy_center,q_auto:best,w_250/v1667924564/Fdbr1DxaMAAFR6S_dgtzuf.webp'} className="com-lists-item-img" />
              <div className="com-lists-item-text">
                <div className="com-lists-item-text-titles">
                  <span className="com-lists-item-text-title">
                    Kenomi #3456
                  </span>
                  <span className="com-lists-item-text-subtitle">
                    21/10/2022 at 10:30 PM
                  </span>
                </div>
                <div className="com-list-buy-info">
                  <span className="com-list-buy-info-span-1">0.5 ETH</span>
                  <span className="com-list-buy-info-span-2">+ 12% floor</span>
                </div>
              </div>
            </div>
            <div className="com-lists-item">
              <img src={'https://res.cloudinary.com/foxledgerstudio/image/upload/c_fill,g_xy_center,q_auto:best,w_250/v1667924564/Fdbr1DxaMAAFR6S_dgtzuf.webp'} className="com-lists-item-img" />
              <div className="com-lists-item-text">
                <div className="com-lists-item-text-titles">
                  <span className="com-lists-item-text-title">
                    Kenomi #3456
                  </span>
                  <span className="com-lists-item-text-subtitle">
                    21/10/2022 at 10:30 PM
                  </span>
                </div>
                <div className="com-list-buy-info">
                  <span className="com-list-buy-info-span-1">0.5 ETH</span>
                  <span className="com-list-buy-info-span-2">+ 12% floor</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {top ? (
          <div className="community-listings-flex-mob">
            <h1 className="community-listings-header">top listings</h1>
            <div className="com-lists">
              <div className="com-lists-item">
                <img src={'https://res.cloudinary.com/foxledgerstudio/image/upload/c_fill,g_xy_center,q_auto:best,w_250/v1667924564/Fdbr1DxaMAAFR6S_dgtzuf.webp'} className="com-lists-item-img" />
                <div className="com-lists-item-text">
                  <div className="com-lists-item-text-titles">
                    <span className="com-lists-item-text-title">
                      Kenomi #3456
                    </span>
                    <span className="com-lists-item-text-subtitle">
                      21/10/2022 at 10:30 PM
                    </span>
                  </div>
                  <div className="com-lists-buy">
                    <span className="com-lists-buy-span">0.5 ETH</span>
                    <img src={blarr} className="com-lists-buy-mob-img" />
                  </div>
                  <div className="com-lists-buy-box">
                    <span className="com-lists-buy-box-span">BUY</span>
                    <img src={arr} alt="BUY" className="arr-icon" />
                  </div>
                </div>
              </div>
              <div className="com-lists-item">
                <img src={'https://res.cloudinary.com/foxledgerstudio/image/upload/c_fill,g_xy_center,q_auto:best,w_250/v1667924564/Fdbr1DxaMAAFR6S_dgtzuf.webp'} className="com-lists-item-img" />
                <div className="com-lists-item-text">
                  <div className="com-lists-item-text-titles">
                    <span className="com-lists-item-text-title">
                      Kenomi #3456
                    </span>
                    <span className="com-lists-item-text-subtitle">
                      21/10/2022 at 10:30 PM
                    </span>
                  </div>
                  <div className="com-lists-buy">
                    <span className="com-lists-buy-span">0.5 ETH</span>
                    <img src={blarr} className="com-lists-buy-mob-img" />
                  </div>
                  <div className="com-lists-buy-box">
                    <span className="com-lists-buy-box-span">BUY</span>
                    <img src={arr} alt="BUY" className="arr-icon" />
                  </div>
                </div>
              </div>
              <div className="com-lists-item">
                <img src={'https://res.cloudinary.com/foxledgerstudio/image/upload/c_fill,g_xy_center,q_auto:best,w_250/v1667924564/Fdbr1DxaMAAFR6S_dgtzuf.webp'} className="com-lists-item-img" />
                <div className="com-lists-item-text">
                  <div className="com-lists-item-text-titles">
                    <span className="com-lists-item-text-title">
                      Kenomi #3456
                    </span>
                    <span className="com-lists-item-text-subtitle">
                      21/10/2022 at 10:30 PM
                    </span>
                  </div>
                  <div className="com-lists-buy">
                    <span className="com-lists-buy-span">0.5 ETH</span>
                    <img src={blarr} className="com-lists-buy-mob-img" />
                  </div>
                  <div className="com-lists-buy-box">
                    <span className="com-lists-buy-box-span">BUY</span>
                    <img src={arr} alt="BUY" className="arr-icon" />
                  </div>
                </div>
              </div>
              <div className="com-lists-item">
                <img src={'https://res.cloudinary.com/foxledgerstudio/image/upload/c_fill,g_xy_center,q_auto:best,w_250/v1667924564/Fdbr1DxaMAAFR6S_dgtzuf.webp'} className="com-lists-item-img" />
                <div className="com-lists-item-text">
                  <div className="com-lists-item-text-titles">
                    <span className="com-lists-item-text-title">
                      Kenomi #3456
                    </span>
                    <span className="com-lists-item-text-subtitle">
                      21/10/2022 at 10:30 PM
                    </span>
                  </div>
                  <div className="com-lists-buy">
                    <span className="com-lists-buy-span">0.5 ETH</span>
                    <img src={blarr} className="com-lists-buy-mob-img" />
                  </div>
                  <div className="com-lists-buy-box">
                    <span className="com-lists-buy-box-span">BUY</span>
                    <img src={arr} alt="BUY" className="arr-icon" />
                  </div>
                </div>
              </div>
              <div className="com-lists-item">
                <img src={'https://res.cloudinary.com/foxledgerstudio/image/upload/c_fill,g_xy_center,q_auto:best,w_250/v1667924564/Fdbr1DxaMAAFR6S_dgtzuf.webp'} className="com-lists-item-img" />
                <div className="com-lists-item-text">
                  <div className="com-lists-item-text-titles">
                    <span className="com-lists-item-text-title">
                      Kenomi #3456
                    </span>
                    <span className="com-lists-item-text-subtitle">
                      21/10/2022 at 10:30 PM
                    </span>
                  </div>
                  <div className="com-lists-buy">
                    <span className="com-lists-buy-span">0.5 ETH</span>
                    <img src={blarr} className="com-lists-buy-mob-img" />
                  </div>
                  <div className="com-lists-buy-box">
                    <span className="com-lists-buy-box-span">BUY</span>
                    <img src={arr} alt="BUY" className="arr-icon" />
                  </div>
                </div>
              </div>
              <div className="com-lists-item">
                <img src={'https://res.cloudinary.com/foxledgerstudio/image/upload/c_fill,g_xy_center,q_auto:best,w_250/v1667924564/Fdbr1DxaMAAFR6S_dgtzuf.webp'} className="com-lists-item-img" />
                <div className="com-lists-item-text">
                  <div className="com-lists-item-text-titles">
                    <span className="com-lists-item-text-title">
                      Kenomi #3456
                    </span>
                    <span className="com-lists-item-text-subtitle">
                      21/10/2022 at 10:30 PM
                    </span>
                  </div>
                  <div className="com-lists-buy">
                    <span className="com-lists-buy-span">0.5 ETH</span>
                    <img src={blarr} className="com-lists-buy-mob-img" />
                  </div>
                  <div className="com-lists-buy-box">
                    <span className="com-lists-buy-box-span">BUY</span>
                    <img src={arr} alt="BUY" className="arr-icon" />
                  </div>
                </div>
              </div>
              <div className="com-lists-item">
                <img src={'https://res.cloudinary.com/foxledgerstudio/image/upload/c_fill,g_xy_center,q_auto:best,w_250/v1667924564/Fdbr1DxaMAAFR6S_dgtzuf.webp'} className="com-lists-item-img" />
                <div className="com-lists-item-text">
                  <div className="com-lists-item-text-titles">
                    <span className="com-lists-item-text-title">
                      Kenomi #3456
                    </span>
                    <span className="com-lists-item-text-subtitle">
                      21/10/2022 at 10:30 PM
                    </span>
                  </div>
                  <div className="com-lists-buy">
                    <span className="com-lists-buy-span">0.5 ETH</span>
                    <img src={blarr} className="com-lists-buy-mob-img" />
                  </div>
                  <div className="com-lists-buy-box">
                    <span className="com-lists-buy-box-span">BUY</span>
                    <img src={arr} alt="BUY" className="arr-icon" />
                  </div>
                </div>
              </div>
              <div className="com-lists-item">
                <img src={'https://res.cloudinary.com/foxledgerstudio/image/upload/c_fill,g_xy_center,q_auto:best,w_250/v1667924564/Fdbr1DxaMAAFR6S_dgtzuf.webp'} className="com-lists-item-img" />
                <div className="com-lists-item-text">
                  <div className="com-lists-item-text-titles">
                    <span className="com-lists-item-text-title">
                      Kenomi #3456
                    </span>
                    <span className="com-lists-item-text-subtitle">
                      21/10/2022 at 10:30 PM
                    </span>
                  </div>
                  <div className="com-lists-buy">
                    <span className="com-lists-buy-span">0.5 ETH</span>
                    <img src={blarr} className="com-lists-buy-mob-img" />
                  </div>
                  <div className="com-lists-buy-box">
                    <span className="com-lists-buy-box-span">BUY</span>
                    <img src={arr} alt="BUY" className="arr-icon" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {!top ? (
          <div className="community-listings-flex-mob">
            <h1 className="community-listings-header">Sales</h1>
            <div className="com-lists">
              <div className="com-lists-item">
                <img src={'https://res.cloudinary.com/foxledgerstudio/image/upload/c_fill,g_xy_center,q_auto:best,w_250/v1667924564/Fdbr1DxaMAAFR6S_dgtzuf.webp'} className="com-lists-item-img" />
                <div className="com-lists-item-text-2">
                  <div className="com-lists-item-text-titles">
                    <span className="com-lists-item-text-title">
                      Kenomi #3456
                    </span>
                    <span className="com-lists-item-text-subtitle">
                      21/10/2022 at 10:30 PM
                    </span>
                  </div>
                  <div className="com-list-buy-info">
                    <span className="com-list-buy-info-span-1">0.5 ETH</span>
                    <span className="com-list-buy-info-span-2">
                      + 12% floor
                    </span>
                  </div>
                </div>
              </div>
              <div className="com-lists-item">
                <img src={'https://res.cloudinary.com/foxledgerstudio/image/upload/c_fill,g_xy_center,q_auto:best,w_250/v1667924564/Fdbr1DxaMAAFR6S_dgtzuf.webp'} className="com-lists-item-img" />
                <div className="com-lists-item-text-2">
                  <div className="com-lists-item-text-titles">
                    <span className="com-lists-item-text-title">
                      Kenomi #3456
                    </span>
                    <span className="com-lists-item-text-subtitle">
                      21/10/2022 at 10:30 PM
                    </span>
                  </div>
                  <div className="com-list-buy-info">
                    <span className="com-list-buy-info-span-1">0.5 ETH</span>
                    <span className="com-list-buy-info-span-2">
                      + 12% floor
                    </span>
                  </div>
                </div>
              </div>
              <div className="com-lists-item">
                <img src={'https://res.cloudinary.com/foxledgerstudio/image/upload/c_fill,g_xy_center,q_auto:best,w_250/v1667924564/Fdbr1DxaMAAFR6S_dgtzuf.webp'} className="com-lists-item-img" />
                <div className="com-lists-item-text-2">
                  <div className="com-lists-item-text-titles">
                    <span className="com-lists-item-text-title">
                      Kenomi #3456
                    </span>
                    <span className="com-lists-item-text-subtitle">
                      21/10/2022 at 10:30 PM
                    </span>
                  </div>
                  <div className="com-list-buy-info">
                    <span className="com-list-buy-info-span-1">0.5 ETH</span>
                    <span className="com-list-buy-info-span-2">
                      + 12% floor
                    </span>
                  </div>
                </div>
              </div>
              <div className="com-lists-item">
                <img src={'https://res.cloudinary.com/foxledgerstudio/image/upload/c_fill,g_xy_center,q_auto:best,w_250/v1667924564/Fdbr1DxaMAAFR6S_dgtzuf.webp'} className="com-lists-item-img" />
                <div className="com-lists-item-text-2">
                  <div className="com-lists-item-text-titles">
                    <span className="com-lists-item-text-title">
                      Kenomi #3456
                    </span>
                    <span className="com-lists-item-text-subtitle">
                      21/10/2022 at 10:30 PM
                    </span>
                  </div>
                  <div className="com-list-buy-info">
                    <span className="com-list-buy-info-span-1">0.5 ETH</span>
                    <span className="com-list-buy-info-span-2">
                      + 12% floor
                    </span>
                  </div>
                </div>
              </div>
              <div className="com-lists-item">
                <img src={'https://res.cloudinary.com/foxledgerstudio/image/upload/c_fill,g_xy_center,q_auto:best,w_250/v1667924564/Fdbr1DxaMAAFR6S_dgtzuf.webp'} className="com-lists-item-img" />
                <div className="com-lists-item-text-2">
                  <div className="com-lists-item-text-titles">
                    <span className="com-lists-item-text-title">
                      Kenomi #3456
                    </span>
                    <span className="com-lists-item-text-subtitle">
                      21/10/2022 at 10:30 PM
                    </span>
                  </div>
                  <div className="com-list-buy-info">
                    <span className="com-list-buy-info-span-1">0.5 ETH</span>
                    <span className="com-list-buy-info-span-2">
                      + 12% floor
                    </span>
                  </div>
                </div>
              </div>
              <div className="com-lists-item">
                <img src={'https://res.cloudinary.com/foxledgerstudio/image/upload/c_fill,g_xy_center,q_auto:best,w_250/v1667924564/Fdbr1DxaMAAFR6S_dgtzuf.webp'} className="com-lists-item-img" />
                <div className="com-lists-item-text-2">
                  <div className="com-lists-item-text-titles">
                    <span className="com-lists-item-text-title">
                      Kenomi #3456
                    </span>
                    <span className="com-lists-item-text-subtitle">
                      21/10/2022 at 10:30 PM
                    </span>
                  </div>
                  <div className="com-list-buy-info">
                    <span className="com-list-buy-info-span-1">0.5 ETH</span>
                    <span className="com-list-buy-info-span-2">
                      + 12% floor
                    </span>
                  </div>
                </div>
              </div>
              <div className="com-lists-item">
                <img src={'https://res.cloudinary.com/foxledgerstudio/image/upload/c_fill,g_xy_center,q_auto:best,w_250/v1667924564/Fdbr1DxaMAAFR6S_dgtzuf.webp'} className="com-lists-item-img" />
                <div className="com-lists-item-text-2">
                  <div className="com-lists-item-text-titles">
                    <span className="com-lists-item-text-title">
                      Kenomi #3456
                    </span>
                    <span className="com-lists-item-text-subtitle">
                      21/10/2022 at 10:30 PM
                    </span>
                  </div>
                  <div className="com-list-buy-info">
                    <span className="com-list-buy-info-span-1">0.5 ETH</span>
                    <span className="com-list-buy-info-span-2">
                      + 12% floor
                    </span>
                  </div>
                </div>
              </div>
              <div className="com-lists-item">
                <img src={'https://res.cloudinary.com/foxledgerstudio/image/upload/c_fill,g_xy_center,q_auto:best,w_250/v1667924564/Fdbr1DxaMAAFR6S_dgtzuf.webp'} className="com-lists-item-img" />
                <div className="com-lists-item-text-2">
                  <div className="com-lists-item-text-titles">
                    <span className="com-lists-item-text-title">
                      Kenomi #3456
                    </span>
                    <span className="com-lists-item-text-subtitle">
                      21/10/2022 at 10:30 PM
                    </span>
                  </div>
                  <div className="com-list-buy-info">
                    <span className="com-list-buy-info-span-1">0.5 ETH</span>
                    <span className="com-list-buy-info-span-2">
                      + 12% floor
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div> */}
      {/* {!isWalletLogin ? (
        <div
          className="floating-connect-button"
          onClick={() => connectWalletThroughMetamask()}
        >
          Connect{" "}
        </div>
      ) : (
        ""
      )} */}
    </div>
  );
};

export default Community;
