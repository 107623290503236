import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useContext, useEffect, useState } from "react";
// import ActivitySelect from './ActivitySelect'
// import { KeyboardArrowDownOutlined } from '@mui/icons-material'
import { WalletConnectContext } from "../../hooks/WalletLogin";
import "./graph.css";
import l1 from "../../Assets/Images/l1.png";
import l2 from "../../Assets/Images/l2.png";
import l3 from "../../Assets/Images/l3.png";
import l4 from "../../Assets/Images/l4.png";
import eth from "../../Assets/Images/eth.png";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const Graph = () => {
  const [finalData, setFinalData] = useState([]);
  const [finalLabels, setFinalLabels] = useState([]);

  const { recentSale, recentSaleLoading } = useContext(WalletConnectContext);

  const parseData = () => {
    const filteredActivities = [];

    let currentYear = new Date().getUTCFullYear();
    let currentMonth = new Date().getUTCMonth();
    let currentDate = new Date().getUTCDate();

    for (let i = 0; i < currentDate; i++) {
      filteredActivities.push(0);
    }

    recentSale.forEach((act) => {
      let actMonth = new Date(act.date).getUTCMonth();
      let actYear = new Date(act.date).getUTCFullYear();
      let actDate = new Date(act.date).getUTCDate();
      if (currentMonth === actMonth && currentYear === actYear) {
        // console.log(act.price)
        filteredActivities[actDate - 1] += parseFloat(act.price);
      }
    });

    let finalActivity = [];
    filteredActivities.forEach((ele) => {
      if (ele.price !== 0) finalActivity.push(ele);
    });

    // console.log(finalActivity)
    let labels = [],
      data = [];

    for (let i = 0; i < finalActivity.length; i++) {
      labels.push(i + 1);
      data.push(finalActivity[i]);
    }

    setFinalLabels(labels);
    // console.log(labels)
    // console.log(data)
    setFinalData(data);
    // setFinalData(finalActivity)
  };

  useEffect(() => {
    parseData();
  }, [recentSale]);

  const labels = finalLabels;
  // const labels2 = []
  let options = {
    maintainAspectRatio: true,
    tension: 0.5,
    responsive: true,
    fill: true,
    pointBorderWidth: 5,
    pointHoverRadius: 5,
    pointHoverBorderWidth: 1,
    scales: {
      y: {
        suggestedMin: 0,
        suggestedMax: 1,
        ticks: {
          color: "#222222",
          display: true,
          fontSize: 20,
          beginAtZero: true,
          stepSize: 0.5,
          callback: function (value, index, ticks) {
            return value + " Eth";
          },
        },
        grid: {
          borderDash: [0],
          lineWidth: 1,
          offset: true,
          display: true,
          color: "#222222",
          fontSize: "4px",
          padding: "2px",
        },
        title: {
          display: true,
          text: "Price Sold",
          font: {
            size: 16,
          },
        },
      },
      x: {
        ticks: {
          color: "#222222",
          beginAtZero: true,
          fontSize: 20,
        },
        grid: {
          display: false,
        },
        title: {
          display: true,
          text: "Days",
          font: {
            size: 16,
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
  };

  let data = {
    labels,
    datasets: [
      {
        label: "  Total Sold",
        data: finalData,
        borderColor: "#222222",
        fill: true,
        scaleStepWidth: 1,
        // backgroundColor: "yellow",
      },
    ],
  };

  // console.log(data)

  const [anchorEl, setAnchorEl] = useState(null);
  const [filterDrop, setFilterDrop] = useState("Last 7 Days");
  const open = Boolean(anchorEl);
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    setAnchorEl(null);
    // console.log(event.target)
    event.target.value
      ? setFilterDrop(event.target.value)
      : setFilterDrop("Last 7 Days");
  };

  const today = new Date();

  return (
    <>
      <div className="graph-page">
        <div className="graph-main">
          <div className="extra-activity">
            <div className="activity-box">
              <img src={l1} className="activity-logos" />
              <div className="activity-infos">
                <span className="activity-infos-title">Items</span>
                <span className="activity-infos-subtitle">2222</span>
              </div>
            </div>
            <div className="activity-box">
              <img src={l2} className="activity-logos" />
              <div className="activity-infos">
                <span className="activity-infos-title">Owners</span>
                <span className="activity-infos-subtitle">998</span>
              </div>
            </div>
            <div className="activity-box">
              <img src={l3} className="activity-logos" />
              <div className="activity-infos">
                <span className="activity-infos-title">Floor Price</span>
                <span className="activity-infos-subtitle">
                  0.5 
                  <img src={eth} className="ls" />
                </span>
              </div>
            </div>
            <div className="activity-box">
              <img src={l4} className="activity-logos" />
              <div className="activity-infos">
                <span className="activity-infos-title">Total Volume</span>
                <span className="activity-infos-subtitle">136</span>
              </div>
            </div>
          </div>
          {recentSaleLoading ? (
            "LOADING..."
          ) : (
            <div className="g-line">
              <Line
                options={options}
                data={data}
                style={{ backgroundColor: "transparent" }}
              />
            </div>
          )}
        </div>
      </div>
      <div className="graph-page-mob">
        <div className="extra-activity">
          <div className="activity-box">
            <img src={l1} className="activity-logos" />
            <div className="activity-infos">
              <span className="activity-infos-title">Items</span>
              <span className="activity-infos-subtitle">2222</span>
            </div>
          </div>
          <div className="activity-box">
            <img src={l2} className="activity-logos" />
            <div className="activity-infos">
              <span className="activity-infos-title">Owners</span>
              <span className="activity-infos-subtitle">998</span>
            </div>
          </div>
          <div className="activity-box">
            <img src={l3} className="activity-logos" />
            <div className="activity-infos">
              <span className="activity-infos-title">Floor Price</span>
              <span className="activity-infos-subtitle">
                0.5 
                <img src={eth} className="ls" />
              </span>
            </div>
          </div>
          <div className="activity-box">
            <img src={l4} className="activity-logos" />
            <div className="activity-infos">
              <span className="activity-infos-title">Total Volume</span>
              <span className="activity-infos-subtitle">136</span>
            </div>
          </div>
        </div>
        <div className="graph-main-srroll">
          <div className="graph-main">
            {recentSaleLoading ? (
              "LOADING..."
            ) : (
              <div className="g-line">
                <Line
                  options={options}
                  data={data}
                  style={{ backgroundColor: "transparent" }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Graph;
