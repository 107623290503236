import React, { useContext } from 'react'
import Loader from './Components/Loader/Loader'
import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import { WalletConnectContext } from './hooks/WalletLogin'

const DiscordConnectPage = () => {
    const location = useLocation()
    const code = location.search.split('=')[1]
    const { connectToDiscord } = useContext(WalletConnectContext)

    useEffect(() => {
        const getDiscordUser = async () => {
            connectToDiscord(code)
        }
        if (code) {
            getDiscordUser()
        }
    }, [code])

    return (
        <div className='flex h-full w-full items-center justify-center relative'>
            <Loader />
        </div>
    )
}

export default DiscordConnectPage
