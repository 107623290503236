import React, { useState, useContext, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import "./navbar.css";
import logo from "../../Assets/Images/logo.png";
import search from "../../Assets/Images/search.png";
import bell from "../../Assets/Images/bell.png";
import { Tooltip } from "@mui/material";
import { CopyToClipboard } from "react-copy-to-clipboard";
import meta from "../../Assets/Images/meta.png";
import discord from "../../Assets/Images/discord.png";
import { WalletConnectContext } from "../../hooks/WalletLogin";
import logo2 from "../../Assets/Images/logo2.png";
import ham from "../../Assets/Images/ham.png";
import clos from "../../Assets/Images/close.png";
import lg from "../../Assets/Images/lg.png";
import { FaDiscord, FaLuggageCart } from "react-icons/fa";
import { BsTwitter } from "react-icons/bs";
import { RiInstagramFill } from "react-icons/ri";
import CreateModal from "../CreateModal/CreateModal";
import KenomiABI from "../../Contract/Kenomi.json";
import { BsMedium } from "react-icons/bs";
import LogoutModal from "../LogoutModal/LogoutModal";
const Web3 = require('web3');
const web3 = new Web3('https://mainnet.infura.io/v3/57e8f9860a3046eea1b604439012bbd3');

const Navbar = ({ title, marketData }) => {
  const { isWalletLogin, connectWalletThroughMetamask, logout, walletAddress, isAdmin } =
    useContext(WalletConnectContext);
  const [menu, setMenu] = useState(1);
  const [create, setCreate] = useState(false);
  const [admin, setAdmin] = useState(false);
  const [logoutModal, setLogoutModal] = useState(false);
  const [copyText, setCopyText] = useState('Copy')
  const locator = useLocation();
  var kenomiInstance;

  const checkAdmin = async () => {
    kenomiInstance = new web3.eth.Contract(
      KenomiABI.abi,
      "0xb82845aeD5131213DEa0a45fAd2ccE920EC332f3"
    );
    const address = `${walletAddress}`;
    const call = await kenomiInstance.methods.adminMapping(address).call();
    // console.log(call);
    setAdmin(call);
  };
  const openCreate = () => {
    // console.log("chal raha hai")
    setCreate(true);
    setMenu(true);
  };
  const lgg = () => {
    setMenu(true);
    setLogoutModal(true);
  };
  useEffect(() => {
    checkAdmin();
  }, [isWalletLogin]);
  return (
    <>
      {create ? <CreateModal marketData={marketData} SetCreate={setCreate} /> : ""}
      {logoutModal ? <LogoutModal SetLM={setLogoutModal} lg={logout} /> : ""}
      <div className="navbar">
        <div className="navbar-section-1">
          <img src={logo} className="navbar-kenomi-header" />
          <div className="vert-divider"></div>
          <Link
            to="/"
            className={
              locator.pathname === "/" ? "navbar-active" : "navbar-passive"
            }
          >
            Market
          </Link>
          <Link
            to="/community"
            className={
              locator.pathname === "/community"
                ? "navbar-active"
                : "navbar-passive"
            }
          >
            Community
          </Link>
          {isWalletLogin ? (
            <>
              {/* <Link
                to="/mynfts"
                className={
                  locator.pathname === "/mynfts"
                    ? "navbar-active"
                    : "navbar-passive"
                }
              >
                My NFTs
              </Link> */}
              <Link
                to="/mypurchases"
                className={
                  locator.pathname === "/mypurchases"
                    ? "navbar-active"
                    : "navbar-passive"
                }
              >
                My Purchases
              </Link>
            </>
          ) : (
            ""
          )}
        </div>
        <div className="navbar-section-2">
          {/* <img src={search} className="navbar-item" /> */}
          {/* <img src={bell} className="navbar-item" /> */}
          {isWalletLogin ? (
            <Tooltip followCursor={true} title={copyText}>
              <div
                className="navbar-button"
                onClick={() => {
                  navigator.clipboard.writeText(walletAddress);

                  navigator.clipboard.readText().then((clipText) => {
                    // console.log(clipText)
                    setCopyText("Copied");
                    setTimeout(() => {
                      setCopyText("Copy");
                    }, 3000);
                  });
                }}
              >
                <img src={meta} alt="MetaMask" className="meta-icon" />
                <span>
                  {walletAddress.substr(0, 4) +
                    "..." +
                    walletAddress.substr(
                      walletAddress.length - 3,
                      walletAddress.length
                    )}
                </span>
              </div>
            </Tooltip>
          ) : (
            ""
          )}
          {isWalletLogin ? (localStorage.getItem('Discord') ? (
            <div
              className="navbar-button"
            >
              <img style={{ width: '28px' }} src={discord} alt="MetaMask" className="meta-icon" />
              <span>
                {localStorage.getItem('Discord')}#{localStorage.getItem('DiscordDiscriminator')}
              </span>
            </div>
          ) : (<div
            className="navbar-button"
            onClick={
              () => window.location.assign('https://discord.com/api/oauth2/authorize?client_id=1050406857530953818&redirect_uri=https%3A%2F%2Fkenomi.org%2Fapi%2Fauth%2Fdiscord%2Fredirect&response_type=code&scope=identify%20guilds%20guilds.members.read')
            }
          >
            Connect Discord
          </div>)) : (
            ""
          )}
          <div
            className="navbar-button"
            onClick={
              isWalletLogin
                ? () => setLogoutModal(true)
                : () => connectWalletThroughMetamask()
            }
          >
            {isWalletLogin ? "Logout" : "Connect Wallet"}
          </div>
          {isAdmin ? (
            <div className="navbar-button" onClick={() => openCreate()}>
              Create Item
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="Navbar-mobile">
        <div className="nav-mobile">
          <img
            src={logo2}
            alt="KENOMI"
            className={menu ? "nav-mob-logo" : "nav-mob-logo-2"}
          />
          <img
            src={menu ? `${ham}` : `${clos}`}
            alt="MENU"
            className="nav-mob-ham"
            onClick={() => setMenu(!menu)}
          />
          {!menu ? <></> : ""}
        </div>
        {!menu ? (
          <div className="mob-nav-dropdown">
            <div className="mob-nav-links">
              {isWalletLogin && admin ? (
                <div className="navbar-button-2" onClick={() => openCreate()}>
                  Create Item
                </div>
              ) : (
                ""
              )}
              <Link
                to="/"
                className={
                  locator.pathname === "/"
                    ? "mob-navbar-active"
                    : "mob-navbar-passive"
                }
              >
                Market
              </Link>
              <Link
                to="/community"
                className={
                  locator.pathname === "/community"
                    ? "mob-navbar-active"
                    : "mob-navbar-passive"
                }
              >
                Community
              </Link>
              {isWalletLogin ? (
                <>
                  {" "}
                  {/* <Link
                    to="/mynfts"
                    className={
                      locator.pathname === "/mynfts"
                        ? "mob-navbar-active"
                        : "mob-navbar-passive"
                    }
                  >
                    My NFTs
                  </Link> */}
                  <Link
                    to="/mypurchases"
                    className={
                      locator.pathname === "/mypurchases"
                        ? "mob-navbar-active"
                        : "mob-navbar-passive"
                    }
                  >
                    My Purchases
                  </Link>
                </>
              ) : (
                ""
              )}
            </div>
            <div className="mob-nav-other-links">
              {isWalletLogin ? (
                <div className="mob-nav-logout" onClick={() => lgg()}>
                  <span>Log Out</span>
                  <img src={lg} className="mob-nav-logout-logo" />
                </div>
              ) : (
                ""
              )}
              <div className="mob-nav-links-div">
                <a target="_blank" href="https://discord.com/invite/wNndP3Fm4v">
                  <FaDiscord className="comm-logo" />
                </a>
                <a target="_blank" href="https://twitter.com/KenomiNFT">
                  <BsTwitter className="comm-logo" />
                </a>
                <a target="_blank" href="https://medium.com/@kenominft">
                  <BsMedium className="comm-logo" />
                </a>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="nav-mobile-flex">
          <h1 className="nav-mobile-flex-title">{title}</h1>
          <div className="nav-mobile-flex-icons">
            {/* <img src={search} className="navbar-item-mob" /> */}
            {/* <img src={bell} className="navbar-item-mob" /> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
