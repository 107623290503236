import React from "react";
import "./loader.css";
import nomi from "../../Assets/Images/nomi.png";
import i from "../../Assets/Images/i.png";
import k from "../../Assets/Images/k.png";
const Loader = () => {
  return (
    <div className="loader">
      <img src={k} alt="K" className="k letter" />
      <div className="loader-e">
        <img src={i} alt="i" className="i-1 letter-2" />
        <img src={i} alt="i" className="i-2 letter-2" />
        <img src={i} alt="i" className="i-3 letter-2" />
      </div>
      <img src={nomi} alt="nomi" className="nomi letter-3" />
    </div>
  );
};

export default Loader;
