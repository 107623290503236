import axios from 'axios'
import { createContext, useEffect, useState } from 'react'
// import { ETHX } from '../data/contracts/constant'
import { minABI } from '../data/contracts/minABI'
import Web3 from 'web3'
import WalletConnectProvider from '@walletconnect/web3-provider'
// export const BACKEND_URL = 'https://kenomi.foxledger.io'
// export const BACKEND_URL = 'http://localhost:5005'
// export const BACKEND_URL = 'https://miniverse-backend3.herokuapp.com'
export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

export const WalletConnectContext = createContext()

export const parseOriginalDate = (date) => {
    const parsedDate = new Date(date).toString().split(' ')
    return `${parsedDate[1]} ${parsedDate[2]}, ${parsedDate[3]}`
}

export const WalletConnectProviderHook = ({ children }) => {
    const [isWalletLogin, setWalletLogin] = useState(localStorage.getItem('WalletAddress') ? true : false)
    const [accessToken, setAccessToken] = useState(null)
    const [purchasedItems, setpurchasedItems] = useState([])
    const [signature, setSignature] = useState(null)
    const [userInfo, setUserInfo] = useState({})
    const [getAllUsers, setGetAllUsers] = useState([])
    const [topStakingInfo, setTopStakingInfo] = useState({})
    const [walletAddress, setWalletAddress] = useState(localStorage.getItem('WalletAddress') ? localStorage.getItem('WalletAddress') : null)
    const [isAdmin, setisAdmin] = useState(localStorage.getItem('WalletAddress') ? (localStorage.getItem('WalletAddress').toLowerCase() == '0x34D14e2574C4f55f1d3E5F801c10d66fcA02D000'.toLowerCase() || localStorage.getItem('WalletAddress').toLowerCase() == '0x33618B6B64Ef35c9d1c76758a83F00Fb5cAD95D2'.toLowerCase()) : false)
    const [updateItems, setupdateItems] = useState(false)
    const [discordData, setDiscordData] = useState({})
    const [dashInfo, setDashInfo] = useState({})
    const [stackInfo, setStackInfo] = useState({})
    const [tokenAmount, setTokenAmount] = useState(0)
    const [userActivity, setUserActivity] = useState([])
    const [roles, setRoles] = useState([])
    const [notifications, setNotifications] = useState([])
    const [notificationLoading, setNotificationLoading] = useState(false)
    const [winnerSaveLoading, setWinnerSaveLoading] = useState(false)
    const [allNfts, setAllNfts] = useState([])
    const [stakeCard, setStakeCard] = useState([])
    const [recentSale, setRecentSale] = useState([])
    const [recentSaleLoading, setRecentSaleLoading] = useState(false)
    const [openModal, setOpenModal] = useState({ open: false, type: 1 })
    const [loading, setLoading] = useState(false)
    const [submiting, setSubmitting] = useState(false)
    const [graphLoading, setGraphLoading] = useState(false)
    const [collectedCoins, setCollectedCoins] = useState(false)
    const [metadataLoading, setMetadataLoading] = useState(false)
    const [getAllUsersLoading, setGetAllUsersLoading] = useState(false)
    const [activityLoading, setActivityLoading] = useState(false)
    const [connectLoading, setConnectLoading] = useState(false)
    const [noWallet, setNoWallet] = useState(false)
    const [walletStatus, setWalletStatus] = useState('')
    const [anchorEl_items, setAnchorEl_items] = useState(null)
    const [activeNftType, setActiveNftType] = useState('All Items')
    const web3 = new Web3(Web3.givenProvider)

    // console.log(accessToken)
    // console.log(signature)

    const open_items = Boolean(anchorEl_items)

    const config = {
        headers: {
            'content-type': 'application/json',
            authorization: `Bearer ${accessToken}`,
        },
    }

    const handleOpenMenu_items = (event) => {
        setAnchorEl_items(event.currentTarget)
    }

    const handleClose_items = (event) => {
        setAnchorEl_items(null)
        // console.log(event.target)
        event.target.value
            ? setActiveNftType(event.target.value)
            : setActiveNftType('All Items')
    }

    const logout = async () => {
        try {
            await localStorage.clear()
            setWalletLogin(false)
            setUserInfo({})
            setUserActivity([])
            setDiscordData({})
            setWalletAddress(null)
            setAccessToken(null)
            setSignature(null)
            window.location.reload()
        } catch (err) {
            console.log(err)
        }
    }

    const connectWalletThroughMetamask = async () => {
        if (noWallet) {
            // setOpenConnectModal(false)
            setOpenModal({ open: true, type: 1 })
        } else {
            setConnectLoading(true)
            try {
                if (window.ethereum) {
                    var address
                    if (window.ethereum.providers) {
                        address = await window.ethereum.providers
                            .find((provider) => provider.isMetaMask)
                            .request({ method: 'eth_requestAccounts' })
                    } else {
                        if (window.ethereum.isMetaMask) {
                            address = await window.ethereum.request({
                                method: 'eth_requestAccounts',
                            })
                        }
                    }
                    if (address && address.length > 0) {
                        await web3.eth
                            .getAccounts()
                            .then(async (data) => {
                                if (data.length > 0) {
                                    if (data[0].length > 1) {
                                        web3.eth
                                            .requestAccounts()
                                            .then(async (accounts) => {
                                        //         web3.eth.personal
                                        //             .sign(_message_, data[0])
                                        //             .then(async (res) => {
                                                        // const signature = res
                                                        // setSignature(res)
                                                        setWalletAddress(
                                                            accounts[0]
                                                        )
                                                        localStorage.setItem('WalletAddress', accounts[0])
                                                        if (accounts[0].toLowerCase() == '0x34D14e2574C4f55f1d3E5F801c10d66fcA02D000'.toLowerCase() || accounts[0].toLowerCase() == '0x33618B6B64Ef35c9d1c76758a83F00Fb5cAD95D2'.toLowerCase()) {
                                                            setisAdmin(true)
                                                        }
                                                        setWalletStatus(
                                                            data.status
                                                        )
                                                        setWalletLogin(true)
                                                        setConnectLoading(false)
                                                        // setOpenConnectModal(
                                                        //     false
                                                        // )
                                                    // })
                                            })
                                    } else {
                                        localStorage.removeItem('metamaskInfo')
                                        setConnectLoading(false)
                                    }
                                    setOpenModal({ open: false, type: 1 })
                                    // setConnectLoading(false)
                                }
                                // setConnectLoading(false)
                            })
                            .catch((err) => {
                                setOpenModal({ open: true, type: 1 })
                                setConnectLoading(false)
                                console.log('Wallet Error:', err)
                            })
                    } else {
                        setConnectLoading(false)
                        setOpenModal({ open: true, type: 1 })
                    }
                } else {
                    setConnectLoading(false)
                    setOpenModal({ open: true, type: 1 })
                }
                // setConnectLoading(false)
            } catch (err) {
                console.log(err)
                setConnectLoading(false)
                // setOpenConnectModal(false);
            }
        }
    }

    const connectToDiscord = async (code) => {
        console.log(code)
            try {
                await axios
                    .post(
                        `${BACKEND_URL}/auth/connectToDiscord`,
                        {
                            code: code,
                        }
                    )
                    .then(async (res) => {
                        if (res.data && res.data.userData && res.data.userData.username) {
                            localStorage.setItem('Discord', res.data.userData.username)
                            localStorage.setItem('DiscordDiscriminator', res.data.userData.discriminator)
                            window.location.assign('/')
                        }
                    })
                    .catch((error) => {
                        console.log(error)
                        window.location.assign('/')
                        return
                    })
            } catch (error) {
                console.log(error)
            }
    }

    const disconnectDiscord = async () => {
        if (isWalletLogin && accessToken) {
            setMetadataLoading(true)
            await axios
                .post(
                    `${BACKEND_URL}/auth/deleteDiscord`,
                    {
                        address: walletAddress,
                    },
                    config
                )
                .then(async (res) => {
                    // await updateLocalUserInfo()
                    // window.location.assign('/')
                    setMetadataLoading(false)
                })
                .catch((err) => {
                    console.log(err)
                    setMetadataLoading(false)
                    return
                })
        }
    }

    const getOpenseaItems = async () => {
        if (accessToken) {
            try {
                setLoading(true)
                var metadata = [],
                    running = true,
                    next = ''
                for (let i = 0; i < 10000; i = i + 20) {
                    if (next) {
                        await axios
                            .get(
                                `https://api.opensea.io/api/v1/assets?asset_contract_address=0x809330569D294E61083898412C2dD939cB570D99&owner=${walletAddress}&cursor=${next}`
                            )
                            .then((res) => {
                                metadata = metadata.concat(res.data.assets)
                                if (res.data.next) {
                                    next = res.data.next
                                } else {
                                    running = false
                                }
                            })
                            .catch((err) => {
                                console.log(err)
                                running = false
                                setLoading(false)
                            })
                    } else {
                        await axios
                            .get(
                                `https://api.opensea.io/api/v1/assets?asset_contract_address=0x809330569D294E61083898412C2dD939cB570D99&owner=${walletAddress}`
                            )
                            .then((res) => {
                                metadata = metadata.concat(res.data.assets)
                                if (res.data.next) {
                                    next = res.data.next
                                } else {
                                    running = false
                                }
                                // console.log(res.data);
                            })
                            .catch((err) => {
                                console.log(err)
                                running = false
                                setLoading(false)
                            })
                    }
                    if (!running) {
                        break
                    }
                }
                setStakeCard(metadata)

                await axios
                    .post(
                        `${BACKEND_URL}/staking/getStakingData`,
                        {
                            stakingData: metadata,
                        },
                        config
                    )
                    .then((res) => {
                        setTopStakingInfo(res.data.data)
                        setLoading(false)
                    })
                    .catch((err) => {
                        console.log(err)
                        setLoading(false)
                    })
            } catch (err) {
                console.log(err)
                setLoading(false)
            }
        }
    }

    const getDiscordRoles = async () => {
        if (isWalletLogin && accessToken && userInfo.isAdmin) {
            setMetadataLoading(true)
            try {
                await axios
                    .post(
                        `${BACKEND_URL}/users/discord/roles`,
                        { owner: walletAddress },
                        config
                    )
                    .then((res) => {
                        // console.log(res.data.roles)
                        setRoles(res.data.roles)
                        setMetadataLoading(false)
                    })
                    .catch((err) => {
                        console.log(err)
                        setMetadataLoading(false)
                    })
            } catch (err) {
                console.log(err)
                setMetadataLoading(false)
            }
        }
    }

    useEffect(() => {
        try {
            document.addEventListener('wheel', function (event) {
                if (
                    document.activeElement.type === 'number' &&
                    document.activeElement.classList.contains('noscroll')
                ) {
                    document.activeElement.blur()
                }
            })
        } catch (err) {
            console.log(err)
        }
    })

    return (
        <WalletConnectContext.Provider
            value={{
                accessToken,
                walletAddress,
                isWalletLogin,
                noWallet,
                walletStatus,
                connectWalletThroughMetamask,
                openModal,
                loading,
                setOpenModal,
                userInfo,
                allNfts,
                connectLoading,
                connectToDiscord,
                discordData,
                stakeCard,
                disconnectDiscord,
                logout,
                getDiscordRoles,
                setActiveNftType,
                handleClose_items,
                open_items,
                anchorEl_items,
                handleOpenMenu_items,
                activeNftType,
                metadataLoading,
                setMetadataLoading,
                activityLoading,
                setActivityLoading,
                graphLoading,
                setGraphLoading,
                topStakingInfo,
                setTopStakingInfo,
                setNotifications,
                setWinnerSaveLoading,
                getAllUsers,
                getOpenseaItems,
                submiting,
                purchasedItems,
                setpurchasedItems,
                isAdmin,
                updateItems,
                setupdateItems,
            }}
        >
            {children}
        </WalletConnectContext.Provider>
    )
}
