import React, { useState, useContext } from "react";
import Navbar from "../../Components/Navbar/Navbar";
import MyPurchasesCard from "../../Components/MyPurchasesCard/MyPurchasesCard";
import KenomiABI from "../../Contract/Kenomi.json";
import { WalletConnectContext } from "../../hooks/WalletLogin";
import { useEffect } from "react";
import Loader from "../../Components/Loader/Loader";
import axios from "axios";
const Web3 = require('web3');
const web3 = new Web3('https://mainnet.infura.io/v3/57e8f9860a3046eea1b604439012bbd3');

const MyPurchases = (props) => {
  const { walletAddress, isWalletLogin, purchasedItems, setpurchasedItems } = useContext(WalletConnectContext);
  const [isLoading, setLoading] = useState(purchasedItems.length > 0 ? false : true);

  var kenomiInstance;

  const getOwnedItems = async () => {
    const purchases = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/auth/getPurchases/${walletAddress}`)
    setpurchasedItems(purchases.data.allPurchases);
    setLoading(false)
  };

  useEffect(()=>{
    getOwnedItems();
  },[])

  if (isLoading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  return (
    <div className="page">
      <div className="imported-nav">
        <Navbar marketData={props.marketData} title="My Purchases" />
      </div>
      <div className="card-display">
        {purchasedItems.length > 0
          ? purchasedItems.map((nft, index) => {
              return (
                <>
                  <MyPurchasesCard nft={nft} key={index} />
                </>
              );
            })
          :  (
            <div className="nif">
              <img src={'https://res.cloudinary.com/foxledgerstudio/image/upload/c_fill,g_xy_center,q_auto:best,w_1000/v1668386024/nif.webp'} alt="No Items Found" className="nif-image" />
            </div>
          )}
      </div>
    </div>
  );
};

export default MyPurchases;
