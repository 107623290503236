import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import Market from "./Pages/Market/Market";
import Community from "./Pages/Community/Community";
import MyPurchases from "./Pages/MyPurchases/MyPurchses";
import MyNFTs from "./Pages/MyNFTs/MyNFTs";
import Loader from "./Components/Loader/Loader";
import { WalletConnectContext } from "./hooks/WalletLogin";
import KenomiABI from "./Contract/Kenomi.json";
import DiscordConnectPage from "./DiscordConnectPage";
import axios from 'axios'
const Web3 = require('web3');
const web3 = new Web3('https://mainnet.infura.io/v3/57e8f9860a3046eea1b604439012bbd3');
var FontFaceObserver = require('fontfaceobserver');

function App() {
  const { isWalletLogin, updateItems } =  useContext(WalletConnectContext);
  const [isLoading, setLoading] = useState(true);
  const [giveawayLoading, setgiveawayLoading] = useState(true);
  const [fontLoading, setfontLoading] = useState(true);
  const [marketData, setmarketData] = useState([]);

  var font = new FontFaceObserver('Ambit400');

  var kenomiInstance;
  const getItems = async () => {
    font.load().then(async function () {
      setfontLoading(false)
    }).catch(function () {
      console.log('Ambit400 failed to load.');
    });
    await axios.get(`${process.env.REACT_APP_BACKEND_URL}/auth/getGiveaways`).then((res) => {
      setgiveawayLoading(false)
      setmarketData(res.data.allGiveaways);
    })
  };

  useEffect(() => {
    getItems();
  }, [updateItems]);

  useEffect(() => {
    if (!fontLoading && !giveawayLoading) {
      setLoading(false)
    }
  }, [giveawayLoading, fontLoading]);

  if (isLoading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Market marketData={marketData} />} />
          <Route path="/community" element={<Community marketData={marketData} />} />
          <Route path="/mypurchases" element={isWalletLogin ? (
                                        <MyPurchases marketData={marketData} />
                                    ) : (
                                        <Navigate to='/' />
                                    )} />
          {/* <Route path="/mynfts" element={isWalletLogin ? (
                                        <MyNFTs marketData={marketData} />
                                    ) : (
                                        <Navigate to='/' />
                                    )} /> */}
          <Route
                                path='/api/auth/discord/redirect'
                                element={
                                        <DiscordConnectPage />
                                }
                            />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
