import React from "react";
import "./mypurchasescard.css";
import weth from "../../Assets/Images/weth.png";
import CardPopup from "../CardPopup/CardPopup";
import { useState } from "react";

const MyPurchasesCard = ({nft}) => {
  const StringDate = new Date(Number(nft.endTime)*1000).toDateString()
  const [modal, setModal] = useState(false);

  return (
    <>
      {/* {modal ? <CardPopup SetModal={setModal} nft={nft} /> : ""} */}
    <div className="card" onClick={() => setModal(true)}>
      <img src={nft.imageUrl} alt="nft" className="card-img" />
      <div className="card-text-1">
        <div className="card-header-1">{nft.title}</div>
        <div className="card-subtext">
          <div className="card-subtext-1">
            <span className="card-subtext-header-1">Purchased On</span>
            <div className="card-subtext-header-price-flex-1">
              <span className="card-subtext-header-price-1">{StringDate.split(' ')[2]} {StringDate.split(' ')[1]}, {StringDate.split(' ')[3]}</span>
            </div>
          </div>
          <div className="card-subtext-2">
            <span className="card-subtext-header-1">purchased for</span>
            <div className="card-subtext-text-1">
              <img src={weth} alt="ETH" className="mpeth" />
              <span className="mpeth-txt">{nft.pricePerItem/1000000000000000000}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default MyPurchasesCard;
