import React, { useEffect } from "react";
import "./cardpopup.css";
import cross from "../../Assets/Images/cross.png";
import { useCountdown } from "../../hooks/Countdown";
import KenomiABI from "../../Contract/Kenomi.json";
import { BACKEND_URL, WalletConnectContext } from "../../hooks/WalletLogin";
import { useContext, useState } from "react";
import BidModal from "./BidModal";
import axios from "axios";
const Web3 = require('web3');
const web3 = new Web3('https://mainnet.infura.io/v3/57e8f9860a3046eea1b604439012bbd3');

const CardPopup = ({ SetModal, nft }) => {
  const { connectWalletThroughMetamask, walletAddress, setupdateItems, isAdmin } =
    useContext(WalletConnectContext);
  const [days, hours, minutes, seconds] = useCountdown(Number(nft.endTime)*1000);
  const [openModal, setopenModal] = useState(false)
  const [downloading, setdownloading] = useState(false)
  const [buyers, setbuyers] = useState(false)
  var kenomiInstance;

const downloadFile = ({ data, fileName, fileType }) => {
  const blob = new Blob([data], { type: fileType })

  const a = document.createElement('a')
  a.download = fileName
  a.href = window.URL.createObjectURL(blob)
  const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
  })
  a.dispatchEvent(clickEvt)
  a.remove()
}

const downloadCSV = () => {
  setdownloading(true)
    if (nft.supply) {
      axios.post(`${BACKEND_URL}/auth/downloadCSV`, { index: nft.index, title: nft.title }).then((res) => {
        console.log(res.data.winners)
        downloadFile({
          data: [res.data.winners].join('\n'),
          fileName: `${nft.title.split(' ')[0]}.csv`,
          fileType: 'text/csv',
      })
      })
    }

    setdownloading(false)
}

  const buyItem = async () => {
    const web3 = new Web3(Web3.givenProvider);
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0x1' }],
      })
    } catch (error) {
      console.log(error)
    }

    const accounts = await web3.eth.getAccounts();

    kenomiInstance = new web3.eth.Contract(
      KenomiABI.abi,
      "0xb82845aeD5131213DEa0a45fAd2ccE920EC332f3"
    );

    const nonce = await kenomiInstance.methods.nonce().call();

    const sigParameter = web3.utils.encodePacked(
      { value: accounts[0], type: "address" },
      { value: nonce, type: "uint256" }
    );

    const hashParameter = web3.utils.soliditySha3({
      type: "bytes",
      value: sigParameter,
    });

    console.log(hashParameter)

    const signature = await web3.eth.accounts.sign(
      hashParameter,
      "57f70516dac3f311474824603ae28da6d866168b8d9264d05bcd620ca68348df"
    ); 

    console.log(signature)
    
    const value = nft.pricePerItem;
    await axios.post(`${BACKEND_URL}/auth/participate`, { index: nft.index, walletAddress: localStorage.getItem('WalletAddress'), title: nft.title, discordUsername: `${localStorage.getItem('Discord')}#${localStorage.getItem('DiscordDiscriminator')}`, pricePerItem: nft.pricePerItem, imageUrl: nft.imageUrl })
    const call = await kenomiInstance.methods.buyWhiteListItem(signature.signature, nft.index, 1).send({ from: accounts[0], value: value });

    setupdateItems((prev) => !prev)
  };
  
  const dated = new Date(Number(nft.endTime)*1000).toString().split('GMT')[0]

  return (
    <div className="cardPopup">
      <div className="popup-img">
        <img
          src={nft.imageUrl}
          alt="NFT"
          className="popup-img-img"
        />
      </div>
      <div className="popup-text">
        <div className="popup-text-primary">
          <div className="popup-text-header">
            <h1 className="popup-text-header-heading">{nft.title}</h1>
            <span className="popup-text-header-tag">{nft.supply ? `Supply Left: ${nft.supplyLeft}` : 'Auction'}</span>
          </div>
          <p className="popup-text-para">
            {nft.description}
          </p>
          <div className="timer-container">
            <div className="card-popup-timer">
              <p className="card-popup-timer-text">
                Sale ends {dated}
              </p>
              <div className="card-popup-timer-time">
                {new Date() > new Date(Number(nft.endTime)*1000) ? (
                  <>
                    <span>00h</span>
                    <span>:</span>
                    <span>00m</span>
                    <span>:</span>
                    <span>00s</span>
                  </>
                ) : (
                  ""
                )}
                {new Date() < new Date(Number(nft.endTime)*1000) ? (
                  <>
                    {days <= 0 ? (
                      <>
                        <span>{hours}h</span>
                        <span>:</span>
                        <span>{minutes}m</span>
                        <span>:</span>
                        <span>{seconds}s</span>
                      </>
                    ) : (
                      <>
                        <span>{days}d</span>
                        <span>:</span>
                        <span>{hours}h</span>
                        <span>:</span>
                        <span>{minutes}m</span>
                      </>
                    )}
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
        <div style={{ flexDirection: 'column' }} className="popup-text-secondary">
          <div
            className="popup-text-secondary-button"
            onClick={walletAddress ? (localStorage.getItem('Discord') ? (seconds > 0 && (nft.supplyLeft > 0 || !nft.supplyLeft)) ? nft.supply ? () => buyItem() : () => setopenModal(true) : "" : () => window.location.assign('https://discord.com/api/oauth2/authorize?client_id=1050406857530953818&redirect_uri=https%3A%2F%2Fkenomi.org%2Fapi%2Fauth%2Fdiscord%2Fredirect&response_type=code&scope=identify%20guilds%20guilds.members.read')) : () => connectWalletThroughMetamask()}
          >
            {walletAddress ? (localStorage.getItem('Discord') ? (seconds >= 0 && (nft.supplyLeft > 0 || !nft.supplyLeft)) ? nft.supply ? `Buy for ${nft.pricePerItem/1000000000000000000} ETH as ${localStorage.getItem('Discord')}` : `Enter your Bid as ${localStorage.getItem('Discord')}` : "Sold Out" : 'Connect Discord') : 'Connect Wallet'}
          </div>
          {isAdmin && <div
            className="popup-text-secondary-button"
            onClick={downloadCSV}
          >
            {downloading ? 'Downloading...' : 'Download CSV'}
          </div>}
        </div>
      </div>
      <img
        src={cross}
        alt="Close"
        className="popup-close"
        onClick={() => SetModal(false)}
      />
      <BidModal openModal={openModal} setopenModal={setopenModal} nft={nft} setupdateItems={setupdateItems} walletAddress={walletAddress} />
    </div>
  );
};

export default CardPopup;
