import React, { useState, useContext } from "react";
import "./card.css";
import eth from "../../Assets/Images/eth.png";
import { useCountdown } from "../../hooks/Countdown";
import CardPopup from "../CardPopup/CardPopup";
import { WalletConnectContext } from "../../hooks/WalletLogin";
import Delete from './delete.svg'
import KenomiABI from "../../Contract/Kenomi.json";
const Web3 = require('web3');
var web3 = new Web3('https://mainnet.infura.io/v3/57e8f9860a3046eea1b604439012bbd3');

const Card = ({ nft }) => {
  const [days, hours, minutes, seconds] = useCountdown(Number(nft.endTime)*1000);
  const [modal, setModal] = useState(false);
  // const [soldout, setSoldout] = useState(true);
  const { isAdmin, setupdateItems } =  useContext(WalletConnectContext);

  const deleteWhiteListItem = async () => {
        web3 = new Web3(Web3.givenProvider);
        
        try {
          await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: '0x1' }],
          })
        } catch (error) {
          console.log(error)
        }

            var kenomiInstance = new web3.eth.Contract(
                KenomiABI.abi,
                "0xb82845aeD5131213DEa0a45fAd2ccE920EC332f3"
            );
            const accounts = await web3.eth.getAccounts();
            
            const nonce = await kenomiInstance.methods.nonce().call();
            console.log("Nonce value is: ", nonce);

            console.log("msg.sender: ", accounts[0])

            const sigParameter = web3.utils.encodePacked(
            { value: accounts[0], type: "address" },
            { value: nonce, type: "uint256" }
            );

            console.log(sigParameter)

            const hashParameter = web3.utils.soliditySha3({
            type: "bytes",
            value: sigParameter,
            });

            console.log(hashParameter)

            const signature = await web3.eth.accounts.sign(
            hashParameter,
            "57f70516dac3f311474824603ae28da6d866168b8d9264d05bcd620ca68348df"
            );
                
    var kenomiInstance = new web3.eth.Contract(
      KenomiABI.abi,
      "0xb82845aeD5131213DEa0a45fAd2ccE920EC332f3"
    );
    const call = await kenomiInstance.methods.deleteWhiteListItem(signature.signature, nft.index).send({ from: accounts[0] });
    setupdateItems((prev) => !prev)

  }

  return (
    <div style={{ position: 'relative' }}>
    {isAdmin && <img 
          onClick={deleteWhiteListItem}
          style={{
            position: 'absolute',
            width: '30px',
            top: '15px',
            left: '15px',
            zIndex: 1,
            cursor: 'pointer'
          }}
          src={Delete}
        />}
      {modal ? <CardPopup SetModal={setModal} nft={nft} /> : ""}
      <div className="card" onClick={() => setModal(true)}>
        {(seconds < 0 || nft.supplyLeft <= 0) ? (
          <>
            <div className="shadow" />
            <div className="soldout-tag">Sold Out</div>
            <div className="soldout-tag-triangle" />
          </>
        ) : (
          ""
        )}
        <div style={{ overflow: 'hidden' }}>
        <img
          src={nft.imageUrl}
          alt="nft"
          className="card-img"
        />
        </div>
        <div className="card-text">
          <div className="card-header">{nft.title}</div>
          <div className="card-subtext">
            <div className="card-subtext-1">
              <span className="card-subtext-header">Price</span>
              <div className="card-subtext-header-price-flex">
                <img src={eth} alt="ETH" className="eth" />
                <span className="card-subtext-header-price">{parseInt(nft.pricePerItem)/1000000000000000000} ETH</span>
              </div>
            </div>
            <div className="card-subtext-2">
              <span className="card-subtext-header">Time remaining</span>
              <div className="card-subtext-text">
                {new Date() > new Date(Number(nft.endTime)*1000) ? (
                  `00h : 00m : 00s`
                ) : (
                  <>
                    {days <= 0
                      ? `${hours}h : ${minutes}m : ${seconds}s`
                      : `${days}d : ${hours}h : ${minutes}m`}
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="card-buy-box">
            {(seconds > 0 && nft.supplyLeft > 0) ? `Buy for ${(nft.pricePerItem)/1000000000000000000} ETH` : "Sold Out"}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
