import React, { useState } from "react";
import Card from "../../Components/Card/Card";
import AuctionCard from "../../Components/Card/AuctionCard";
import Navbar from "../../Components/Navbar/Navbar";
import "./market.css";

const Market = (props) => {
  const data = props.marketData

  return (
    <>
      <div className="page">
        <div className="imported-nav">
          <Navbar marketData={props.marketData} title="Market" />
        </div>
        <div className="card-display">
          {/* <Card nft={{ supplyLeft: 5, title: 'Test', supply: 10, pricePerItem: 10000000000000000, endTime: 1670607100, index: 1, imageUrl: 'https://res.cloudinary.com/foxledgerstudio/image/upload/c_fill,g_xy_center,q_auto:best,w_1000/v1670182314/Fida76rVUAA2w8Q_utcgir.webp' }} /> */}
          {data.length > 0 ? (
            data.map((nft, index) => {
              if (nft.supply) {
                return (
                  <Card nft={nft} key={index} />
                )
              } else {
                return (
                  <AuctionCard nft={nft} key={index} />
                );
              }
            })
          ) : (
            <div className="nif">
              <img src={'https://res.cloudinary.com/foxledgerstudio/image/upload/c_fill,g_xy_center,q_auto:best,w_1000/v1668386024/nif.webp'} alt="No Items Found" className="nif-image" />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Market;
