import React, { useState, useContext } from "react";
import "./createmodal.css";
import { utils } from "ethers";
import KenomiABI from "../../Contract/Kenomi.json";
import { WalletConnectContext } from "../../hooks/WalletLogin";
import axios from 'axios'
const Web3 = require('web3');
const web3 = new Web3('https://mainnet.infura.io/v3/57e8f9860a3046eea1b604439012bbd3');

const CreateModal = ({ SetCreate, marketData }) => {
  const [image, setImage] = useState("");
  const [url, setUrl] = useState("");
  const [Auction, setAuction] = useState(false);
  const [uploadingMessage, setuploadingMessage] = useState('');
  const [error, seterror] = useState("");
  const { setupdateItems } = useContext(WalletConnectContext);

  var kenomiInstance = new web3.eth.Contract(
    KenomiABI.abi,
    "0xb82845aeD5131213DEa0a45fAd2ccE920EC332f3"
  );

  const checkAdmin = async () => {
    const address = document.getElementById("checkAdminAddr").value;
    const call = await kenomiInstance.methods.adminMapping(address).call();
  };

  const addAdmin = async () => {
    const address = document.getElementById("addAdminAddr").value;
    const accounts = await web3.eth.getAccounts();

    const call = await kenomiInstance.methods
      .addAdmin(address)
      .send({ from: accounts[0] });
  };

  const removeAdmin = async () => {
    const address = document.getElementById("addAdminAddr").value;
    const accounts = await web3.eth.getAccounts();

    const call = await kenomiInstance.methods
      .removeAdmin(address)
      .send({ from: accounts[0] });
  };

  const addItem = async () => {
    setuploadingMessage('Uploading Image...')
    const data = new FormData()
    data.append("file", image)
    data.append("upload_preset","FoxLedgerStudio-Kenomi")
    await fetch("  https://api.cloudinary.com/v1_1/foxledgerstudio/image/upload",{
    method:"post",
    body: data
    })
    .then(resp => resp.json())
    .then(async (data) => {
    setuploadingMessage('Creating Item...')
    const web3 = new Web3(Web3.givenProvider);

    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0x1' }],
      })
    } catch (error) {
      console.log(error)
    }

    var kenomiInstance = new web3.eth.Contract(
        KenomiABI.abi,
        "0xb82845aeD5131213DEa0a45fAd2ccE920EC332f3"
      );
      const accounts = await web3.eth.getAccounts();

      var itemSupply, itemPrice, minimumBid
      if (!Auction) {
        itemSupply = document.getElementById("addItemSupply").value;
        itemPrice = document.getElementById("addItemPrice").value;
      } else {
        minimumBid = document.getElementById("addItemBid").value ? document.getElementById("addItemBid").value : 0;
      }

      const itemDesc = document.getElementById("addItemDesc").value;
      const itemTitle = document.getElementById("addItemTitle").value;
      const endTime = document.getElementById("endTime").value*60*60;
      const itemImage = data.url

      if((!Auction && !itemSupply) || (!Auction && !itemPrice) || !itemDesc || !itemTitle || !itemImage || !endTime) {
        seterror("Please fill all the fields")
        setuploadingMessage('')
        return
      } else {
        seterror("")
      }
      const nonce = await kenomiInstance.methods.nonce().call();
    const sigParameter = web3.utils.encodePacked(
      { value: accounts[0], type: "address" },
      { value: nonce, type: "uint256" }
    );

    const hashParameter = web3.utils.soliditySha3({
      type: "bytes",
      value: sigParameter,
    });

    const signature = await web3.eth.accounts.sign(
      hashParameter,
      "57f70516dac3f311474824603ae28da6d866168b8d9264d05bcd620ca68348df"
    );

    console.log(signature)
    
      if (Auction) {
        const index = await kenomiInstance.methods.getNextAuctionItemIndex().call();
        console.log(minimumBid, index)
        const zero = "0x0000000000000000000000000000000000000000"
        const item = [index, zero, minimumBid*100000000000000000, endTime];
        const call = await kenomiInstance.methods.addAuctionItem(signature.signature, item, endTime).send({ from: accounts[0] });
        await axios.post(`${process.env.REACT_APP_BACKEND_URL}/auth/create`, { index, itemDesc, itemTitle, itemImage, type: 'Auction' })
      } else {
        const index = await kenomiInstance.methods.getNextWhiteListItemIndex().call();
        const price = utils.parseEther(itemPrice)
        const item = [index, itemSupply, itemSupply, price, endTime];
        const call = await kenomiInstance.methods.addWhiteListItem(signature.signature, item, endTime).send({ from: accounts[0] });
        await axios.post(`${process.env.REACT_APP_BACKEND_URL}/auth/create`, { index, itemDesc, itemTitle, itemImage, type: 'Whitelist', itemSupply })
      }
      
      SetCreate(false)
      setupdateItems((prev) => !prev)

    })
    .catch(err => {
      seterror("Unexpected Error")
        setuploadingMessage('')
      console.log(err)
    })    
  };
  return (
    <div className="create-modal page">
      <div className="crate-md-btn" onClick={() => SetCreate(false)}>
        Close
      </div>
      <div className="creat-modal-flex1">
      <input
          type="text"
          id="addItemTitle"
          placeholder="Item Title"
          className="create-modal-input"
        />
        <input
          type="text"
          id="addItemDesc"
          placeholder="Item Description"
          className="create-modal-input"
        />
        {!Auction && <input
          type="text"
          id="addItemSupply"
          placeholder="Item Supply"
          className="create-modal-input"
        />}
        {!Auction && <input
          type="text"
          id="addItemPrice"
          placeholder="Price Per Item"
          className="create-modal-input"
        />}
        {Auction && <input
          type="text"
          id="addItemBid"
          placeholder="Minimum Bid"
          className="create-modal-input"
        />}
        <input
          type="Number"
          id="endTime"
          placeholder="End Time (Hours)"
          className="create-modal-input"
        />
        <input
          type="file"
          id="addItemImage"
          placeholder="Item Image"
          className="create-modal-input"
          accept="image/*"
          onChange={(e) => setImage(e.target.files[0])}
        />
        <div style={{ display: 'flex', gap: '20px' }}>
          <input
            style={{ width: '20px' }}
            type="checkbox"
            id="checkbox"
            onChange={(e) => { setAuction(e.target.checked) }}
          />
          <h3 style={{ fontFamily: 'sans-serif' }}>Auction</h3>
        </div>
        <button
          type="submit"
          onClick={() => addItem()}
          className="navbar-button"
          disabled={uploadingMessage}
        >
          {uploadingMessage ? uploadingMessage : 'Create Item'}
        </button>
        <p style={{ color: '#e00000' }}>{error}</p>
      </div>
      <div className="creat-modal-flex2">
        <div className="crate-modal-flex-flex">
          <input
            type="text"
            id="checkAdminAddr"
            placeholder="Check Address"
            className="create-modal-input"
          />
          <button
            type="submit"
            onClick={() => checkAdmin()}
            className="navbar-button"
          >
            Check Admin
          </button>
        </div>
        <div className="crate-modal-flex-flex">
          <input
            type="text"
            id="addAdminAddr"
            placeholder="Add Address"
            className="create-modal-input"
          />
          <button
            type="submit"
            onClick={() => addAdmin()}
            className="navbar-button"
          >
            Add Admin
          </button>
        </div>
        <div className="crate-modal-flex-flex">
          <input
            type="text"
            id="removeAdminAddr"
            placeholder="Remove Address"
            className="create-modal-input"
          />
          <button
            type="submit"
            onClick={() => removeAdmin()}
            className="navbar-button"
          >
            Remove Admin
          </button>
        </div>
      </div>
    </div>
  );
};
export default CreateModal;
